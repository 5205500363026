import React from 'react';
import classNames from 'classnames';
import { Title } from '../../elements/Title/title';
import './blogItem.scss';
import RichText from "../RichText/richText";
import { Button } from '../../elements/Button/button';

const BlogItem = props => {

    const item = props.item;
    const textStatic = props.textStatic;
    const classnames = classNames('m-blogItem', {
        'm-blogItem--big': props.big,
        'm-blogItem--small': props.small,
    });

    return(
        <div className={props.className ? props.className + ` ` + classnames : classnames}>
            <a href={item.page}>

                <img src={item.image} />

                <div className='m-blogItem-text'>
                    <Title className="marg-t0" size16 content={item.title}/>
                    <div className='m-blogItem-text-content'>
                        <RichText size13 content={item.preview} />
                    </div>
                    <Button onClickFunction={function(){}} link={item.page} primary small smallRight content={textStatic.blog_item_view_btn}/>
                </div>
                
            </a>
        </div>
    );
}

export default BlogItem;
import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import "./button.scss";

export const Button = props => {
    const classnames = classNames("m-button", {
        "m-button--primary": props.primary,
        "m-button--small": props.small,
        "m-button--monster": props.monster,
        "m-button--small-right": props.smallRight,
        'm-button--fit-content': props.fitContent,

    });
    return (
        <div
            className={props.className ? props.className + ` ` + classnames : classnames}
            onClick={() => props.onClickFunction()}
            aria-hidden="true"
        >
            <Link to={props.link} title={props.linkTitle} target={props.blank?'_blank':'_self'}>
                {props.icon && (<img src={props.icon} width={props.widthIcon} alt=""/>)}
                {props.content}
            </Link>
        </div>
    );
};

import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import "./breadcrumbs.scss";

export const Breadcrumbs = props => {
    const classnames = classNames("m-breadcrumbs", {
    });
    const classnamesItems = classNames("m-breadcrumbs--item", {
    });
    const itemClass = ["second", "third", "fourth", "fifth"];
    let liItems = [];
    let classnamesItemBase = `${classnamesItems} m-breadcrumbs--item-`;

    for (const [key, value] of Object.entries(props.content)) {

        let className = classnamesItemBase + itemClass[key];
        for (const [label, link] of Object.entries(value)) {
            let newItem =
                <li className={className} key={key+1}>
                    <Link to={`${props.host}/${link}/`}>/ {label}</Link>
                </li>;
            liItems.push(newItem);
        }
    }

    return (
        <ul className={props.className ? props.className + ` ` + classnames : classnames}>
            <li className={`${classnamesItems} m-breadcrumbs--item-first`} key={0}>
                <Link to={`${props.host}`}>Home</Link>
            </li>
            {liItems}
        </ul>

);
};

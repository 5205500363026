import React from 'react';
import './pagination.scss';
import { Button } from '../../elements/Button/button';

const Pagination = props => {
    const pagination = props.pagination;
    return(
        <div class="m-pagination">
            {pagination.map((value, index) => (
                <Button onClickFunction={function(){}}  link={value.url} primary={value.selected} fitContent small content={value.number}/>
            ))}
        </div>
    );
};

export default Pagination;
import React from "react"

const jsonBreadcrumbs = props => {

    let liItems = [];

    for (const [key, value] of Object.entries(props.data)) {
        for (const [label, link] of Object.entries(value)) {
            let newItem =
                {
                    "@type": "ListItem",
                    "position": key,
                    "name": label,
                    "item": props.host + '/' + link + '/'
                };

            liItems.push(newItem);
        }
    }

    const ldjson = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": liItems
    };


    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(ldjson) }}
        />
    )
}

export default jsonBreadcrumbs
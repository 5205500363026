import React, { Component } from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import Column from "../../components/structure/Column/column.js";
import Seo from "../../components/seo/seo.js";
import {Title} from "../../components/elements/Title/title";
import {Breadcrumbs} from "../../components/modules/Breadcrumbs/breadcrumbs";
import RichText from "../../components/modules/RichText/richText";
import JsonBreadcrumbs from "../../components/seo/ld-json/jsonBreadcrumbs.js";
import Jumbotron from "../../components/modules/Jumbotron/jumbotron.js";
import BlogItem from '../../components/modules/Blog/blogItem';
import Pagination from '../../components/modules/Blog/pagination';

class Blog extends Component {

    render() {
        const seo = this.props.pageContext.seo;
        const textStatic = this.props.pageContext.textStatic;
        const mainTitle = seo.mainTitle || false;
        const subTitle = seo.subTitle || false;
        const subTitleRel = seo.subTitleRel || false;
        const image = this.props.pageContext.image || false;
        const image2 = this.props.pageContext.image2 || false;
        const text = this.props.pageContext.text || false;
        const text2 = this.props.pageContext.text2 || false;
        const blog_rel = this.props.pageContext.blog_rel || false;
        const arrayBlogs = this.props.pageContext.arrayBlogs || false;
        const pagination = this.props.pageContext.pagination || false;
        const breadcrumbs = this.props.pageContext.breadcrumbs || false;

        return (
            <Layout>
                <Seo lang={seo.lang} title={seo.title} url={seo.url} image={seo.image} description={seo.description}  robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
                <Module withoutPad bg>
                    <Wrapper withoutPad>
                        <Column adapt>
                            <Breadcrumbs content={breadcrumbs} host={seo.host}/>
                            <JsonBreadcrumbs data={breadcrumbs} host={seo.host}/>
                        </Column>
                    </Wrapper>
                </Module>

                <Jumbotron
                    smallPad
                    title={mainTitle}
                    titleTag={`h1`}
                />
                <Module withoutPad>
                    <Wrapper>
                        {text &&
                            <Column row>
                                {image && (
                                    <img className="marg-b20" src={image} />
                                )}

                                {text && (
                                    <RichText content={text} />
                                )}

                                {image2 && (
                                    <img className="marg-b20" src={image2} />
                                )}

                                {text2 && (
                                    <RichText content={text2} />
                                )}
                            </Column>
                        }

                        <Column className='marg-t30' row>

                            {subTitleRel && <Title tag={`h2`} className='marg-b20' centered size18 content={subTitleRel}/>}

                            {blog_rel && blog_rel.map((value, index) => (
                                <Column col='third' colSm='half' colXs='full'>
                                    <BlogItem item={value} textStatic={textStatic}/>
                                </Column>
                            ))}

                            {arrayBlogs && arrayBlogs.map((value, index) => (
                                <Column col='half' colSm='half' colXs='full'>
                                    <BlogItem item={value} textStatic={textStatic}/>
                                </Column>
                            ))}

                            {pagination && <Pagination pagination={pagination}/>}

                        </Column>
                    </Wrapper>
                </Module>
            </Layout>
        );
    }
}

export default Blog;
import React from 'react';
import classNames from 'classnames';
import { Title } from '../../elements/Title/title';
import Wrapper from '../../structure/Wrapper/wrapper';
import Module from '../../structure/Module/module';
import Column from '../../structure/Column/column';
import './jumbotron.scss';

const Jumbotron = props => {

    const classnames = classNames('m-jumbotron', {
        'm-jumbotron--smallPad': props.smallPad
    });

    return(
        <Module withoutPad >
                <div className={classnames} style={{ backgroundImage: `url(${props.bg})` }}>
                    <div className={'m-jumbotron__title'}>
                        <Wrapper withoutPad>
                            <Column adapt>
                                {props.rating && props.rating}
                                <Title tag={props.titleTag&&props.titleTag} size24 content={props.title} />
                                {/* {props.subtitle && <Title normal size16 content={props.subtitle} />} */}
                            </Column>
                        </Wrapper>
                    </div>
                </div>
                {props.logo && <img className='m-jumbotron__logo' height="44" width="44" src={props.logo} />}
        </Module>
    );
}

export default Jumbotron;